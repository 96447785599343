.contact-sec {
    display: grid;
    width: 100%;
    height: 50vh;
    place-content: center;
}

.contact-wrap{
  padding: 80px 0;
}

.contact-wrap .contact-right{
    color: #fff;
}
.inside-text{
    position: relative;
    color: #fff !important;
    z-index: 999;
    transform: translate(120px,110px);
}

.contact-sec .contact_text {
    animation: spin 2s linear infinite;
    background: linear-gradient(to bottom, var(--black), var(--black)) padding-box,
                conic-gradient(
                    in oklch from var(--gradient-angle),
                    var(--purple),
                    var(--orange),
                    var(--yellow),
                    var(--blue),
                    var(--purple)
                )
                border-box;
    border: 5px solid transparent;
    border-radius: 20px;
    position: relative;
    width: 300px;
    height: 200px;
    z-index: 10;
}

.contact_text::before {
    background: black;
    border-radius: 15px;
    content: "";
    position: absolute;
    inset: 0;
    z-index: 5;
}

.contact_text::after {
    animation: spin 2s linear infinite;
    background: conic-gradient(
        in oklch from var(--gradient-angle),
        var(--purple),
        var(--orange),
        var(--yellow),
        var(--blue),
        var(--purple)
    );
    border-radius: 15px;
    content: "";
    position: absolute;
    inset: 0;
    filter: blur(50px);
    z-index: 1;
}
