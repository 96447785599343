.contact-form-wrapper {
    padding: 40px 0;
  }
  
  .contact-form {
    padding: 30px 40px;
    background-color: transparent;
    border-radius: 12px;
    width: 75%;
    max-width: 100%;
    border: 1px solid #fff;
    box-shadow: 15px 15px 30px #bf66ff, -20px -15px 35px #66edff;
    animation: shadow-box 2s linear infinite;
  }
  
  .contact-form textarea {
    resize: none;
  }
  
  .contact-form .form-input,
  .form-text-area {
    background-color: #f0f4f5;
    height: 50px;
    padding-left: 16px;
  }
  
  .contact-form .form-text-area {
    background-color: #f0f4f5;
    height: auto;
    padding-left: 16px;
  }
  
  .contact-form .form-control::placeholder {
    color: #aeb4b9;
    font-weight: 500;
    opacity: 1;
  }
  
  .contact-form .form-control:-ms-input-placeholder {
    color: #aeb4b9;
    font-weight: 500;
  }
  
  .contact-form .form-control::-ms-input-placeholder {
    color: #aeb4b9;
    font-weight: 500;
  }
  
  .contact-form .form-control:focus {
    border-color: #f33fb0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07), 0 0 8px #f33fb0;
  }
  
  .contact-form .title {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    color: #fff;
    font-family: 'Cinzel', serif;
  }

  .contact-title{
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    color: #fff;
    font-family: 'Cinzel', serif;
  }
  
  .contact-form .description {
    color: #aeb4b9;
    font-size: 14px;
    text-align: center;
  }
  
  .contact-form .submit-button-wrapper {
   
    margin-left: 45%;
  }
  
  .contact-form .submit-button-wrapper input {
    border: none;
    border-radius: 4px;
    background-color: #f23292;
    color: white;
    text-transform: uppercase;
    padding: 10px 60px;
    font-weight: 500;
    letter-spacing: 2px;
  }
  
  .contact-form .submit-button-wrapper input:hover {
    background-color: #d30069;
  }
  