.App {
  text-align: center;
}

@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@500;600&display=swap');

.cover-body{
  background-image: url(../src/image/back2.jpg);
 background-repeat: no-repeat;
 background-size: cover;
 overflow: hidden;
}


@media (max-width: 767.98px){
 
  .hero-left{
   display: none;
  }

  .card-box{
   width: 100%;
  }

  .rightside{
    padding: 40px 0;
  }

  .heading {
    font-size: 40px !important ;
}
.taby-tabs{
  width: 75% !important;
}

.btn-12 span{
  width: 60% !important;
}

.btn-12{
  left: 65% !important;

}

.contact-form{
  width: 100% !important;
}
.contact-form .submit-button-wrapper {
  margin-left: 30% !important;
}

.card.is-active .card-jobtitle {
  left: 30% !important;
}

}


@media (min-width: 576px) { 
    
  .heading {
    font-size: clamp(4rem, 7vw, 4rem) ;
}
.taby-tabs{
  width: 75%;
}


 }