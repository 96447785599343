
.card-box {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  height: 25rem;
  background-color: #EEEEEE;
  border-radius: 15px;
  margin-top: 5%;
 
  overflow: hidden;
}

.card-box p:last-child {
  margin: 0;
}

.card-box .image-wrap {
  position: relative;
  max-height: 250px;
  margin: 0;
  height: 10rem;
}

.card-box .image-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

/* .card-box .price {
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 0.25rem;
  
  font-size: 18px;
  font-weight: 700;
} */


.custom-btn {
  width: 130px;
  height: 40px;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
   box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  outline: none;
}

.btn-12{
  position: relative;
  left: 80%;
  bottom: 8vh;
  border:none;
  box-shadow: none;
  /* width: 130px;
  height: 40px; */
  line-height: 42px;
  -webkit-perspective: 230px;
  perspective: 230px;
}
.btn-12 span {
  background: rgb(0,172,238);
background: linear-gradient(90deg, #66edff, #bf66ff);;
  display: block;
  position: absolute;
  width: 70%;
  height: 40px;
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  border-radius: 5px;
  margin:0;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .3s;
  transition: all .3s;
}
.btn-12 span:nth-child(1) {
  box-shadow:
   -7px -7px 20px 0px #fff9,
   -4px -4px 5px 0px #fff9,
   7px 7px 20px 0px #0002,
   4px 4px 5px 0px #0001;
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}
.btn-12 span:nth-child(2) {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}
.btn-12:hover span:nth-child(1) {
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.btn-12:hover span:nth-child(2) {
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
 color: transparent;
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}

.card-box .image-wrap .price span {
  font-size: 12px;
  margin-top: -2px;
}

.card-box .image-wrap .price span a {
 color: #fff;
 text-decoration: none;
}

.card-box .image-wrap .price::-webkit-scrollbar {
  width: 8px;
}

.card-box .image-wrap .price::-webkit-scrollbar-track {
  box-shadow: 0;
  border-radius: 0;
}

.card-box .image-wrap .price::-webkit-scrollbar-thumb {
  background: #FFC857;
  border-radius: 1rem;
}

.card-box .content-box {
  position: relative;
  padding: 16px 12px 32px 24px;
  margin: 16px 8px 8px 0;
  max-height: 290px;
  overflow-y: scroll;
}

.card-box .content-box .title {
  position: relative;
  margin: 0 0 24px;
  padding-bottom: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.card-box .content-box .title::after {
  position: absolute;
  display: block;
  width: 75%;
  height: 2px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #FFC857;
  content: "";
}

.card-box .content-box hr {
  margin: 24px auto;
  width: 50px;
  border-top: 2px solid #FFC857;
}

.card-box .content-box p {
  margin: 0 0 24px;
  font-size: 14px;
  line-height: 1.5;
}

.card-box .content-box p:last-child {
  margin: 0;
}




/* AllProjects.css */

/* AllProjects.css */

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden; /* Ensure no overflow during full-screen */
}

.video-container {
  position: relative;
  width: 80%; /* Adjust the width as needed */
  max-width: 800px; /* Set a maximum width if desired */
}

.responsive-video {
  width: 100%;
  height: 100%;
}

.video-controls {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

.video-modal button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
}

.video-modal button:focus {
  outline: none;
}

.blog-ul{
  padding-left: 0;
}
