.hero-wrapper{
    color: white;
    position: relative;
    padding-bottom: 2rem;
    /* background: #000; */
    position: relative;
    padding: 80px 0;
}
.hero-container{
    justify-content: space-between;
    align-items: flex-end;
}

/* right side */

.image-container{
    width: 30rem;
    height: 35rem;
    overflow: hidden;
    border-radius: 15rem 15rem 0 0;
    border: 8px solid rgba(255, 255, 255, 0.12);

}
.image-container>img{
    width: 100%;
    height: 100%;
}

/* left side */
.hero-left{
    gap: 3rem;
}
.hero-title{
 position: absolute;
 z-index: 1;
 top: 40%;
}
.hero-title>h1{
    font-weight: 600;
    font-size: 3.8rem;
    font-family: 'fangsong';
    line-height: 4rem;
}
.orange{
    height: 4rem;
    width: 4rem;
    background: linear-gradient(270deg, #ffb978 0%, #ff922d 100%);
    border-radius: 999px;
    position: absolute;
    right: 96%;
    top: -15%;
    z-index: -1;
}

.white-gradient {
    position: absolute;
    width: 20rem;
    height: 20rem;
    background: rgba(225, 225, 255, 0.522);
    filter: blur(100px);
    border-radius: 100px;
}



    
